import { login, logout, getInfo, safeLoginCode, register } from '@/api/user'
import {
  getToken, setToken, removeToken,
  getAccount, setAccount, removeAccount
} from '@/utils/auth'
import Vue from 'vue'
import { setTradingPairInfo } from '@/utils/methApi/tradingPairDefault'

const state = {
  token: getToken(),
  account: getAccount(),
  type: '',//等级
  available_time: '',//有效时间
  telegram: '',
  email: '',
  safe_login: '',
  multi_login: '',
  ip_check: '',
  code: '',
  invite_url: '',
  pay_set: '',//自动续费

  novice: localStorage.getItem('novice') || '1',//是否显示新手引导

  // 安全登录
  safe_account: localStorage.getItem("safe_account") || '',
  safe_email: localStorage.getItem("safe_email") || '',

  // 切换账号列表记录
  accountList: {},
}

const mutations = {
  SET_TOKEN: (state, token) => {
    if (!token) {
      removeToken()
    } else {
      setToken(token)
    }
    state.token = token
  },
  SET_NAME: (state, account) => {
    if (!account) {
      removeAccount()
    } else {
      setAccount(account)
    }
    state.account = account
  },
  SET_TYPE: (state, type) => {
    state.type = type
  },
  SET_AVAILABLETIME: (state, available_time) => {
    state.available_time = available_time
  },
  SET_TELEGRAM: (state, telegram) => {
    state.telegram = telegram
  },
  SET_EMAIL: (state, email) => {
    state.email = email
  },
  SET_SAFE_LOGIN: (state, safe_login) => {
    state.safe_login = safe_login
  },
  SET_MULTI_LOGIN: (state, multi_login) => {
    state.multi_login = multi_login
  },
  SET_IP_CHECK: (state, ip_check) => {
    state.ip_check = ip_check
  },

  SET_CODE: (state, code) => {
    state.code = code
  },
  SET_INVITE: (state, invite_url) => {
    state.invite_url = invite_url
  },
  //是否显示新手引导
  SET_NOVICE: (state, novice) => {
    state.novice = novice
  },
  // 是否自动续费
  SET_PAY_SET: (state, pay_set) => {
    state.pay_set = pay_set
  },

  // 安全登录
  SET_SAFE_ACCOUNT: (state, safe_account) => {
    localStorage.setItem("safe_account", safe_account)
    state.safe_account = safe_account
  },
  SET_SAFE_EMAIL: (state, safe_email) => {
    localStorage.setItem("safe_email", safe_email)
    state.safe_email = safe_email
  },

  // 切换账号列表
  SET_ACCOUNT_LIST(state, accountList) {
    state.accountList = accountList;
  },
  DEL_ACCOUNT_ITEM: (state, accountName) => {
    Vue.delete(state.accountList, accountName);
    var accountListStr = JSON.stringify(state.accountList)
    localStorage.setItem('account_list', accountListStr)
  },
  ADD_ACCOUNT_ITEM(state, data) {
    Vue.set(state.accountList, data.accountName, data.account);
    var accountListStr = JSON.stringify(state.accountList)
    localStorage.setItem('account_list', accountListStr)
  },
}

const actions = {
  // register
  register({ dispatch }, data) {
    return new Promise((resolve, reject) => {
      register(data).then(response => {
        // 如果存在token，则退出登录
        if (localStorage.getItem("light_token")) {
          dispatch('logout').then(() => {
            // 数据更新的通用方法
            dispatch('loginInit', response).then(()=>{
              resolve()
            })
          })
        } else {
          // 数据更新的通用方法
          dispatch('loginInit', response).then(()=>{
            resolve()
          })
        }
      }).catch(error => {
        reject(error)
      })
    })
  },
  // user login
  login({ dispatch }, userInfo) {
    const { account, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ account: account.trim(), password: password }).then(response => {
        const data = response

        // 数据更新的通用方法
        dispatch('loginInit', data).then(()=>{
          resolve()
        })

      }).catch(error => {
        reject(error)
      })
    })
  },

  // 安全登录
  safeLogin({ commit, state, dispatch }, sms_code) {
    return new Promise((resolve, reject) => {
      safeLoginCode({
        account: state.safe_account.trim(),
        email: state.safe_email,
        sms_code
      }).then(response => {
        const data = response
        // 初始化安全登录的账户、邮箱
        commit('SET_SAFE_ACCOUNT', '')
        commit('SET_SAFE_EMAIL', '')

        // 数据更新的通用方法
        dispatch('loginInit', data).then(()=>{
          resolve()
        })
        
      }).catch(error => {
        reject(error)
      })
    })
  },

  // 登录、安全登录、注册后 数据更新的通用方法
  loginInit({ commit }, data) {
    return new Promise((resolve, reject) => {
      // 登录后缓存token及用户名
      commit('SET_TOKEN', data.login_token || '')
      commit('SET_NAME', data.user_name || '')

      // 特殊账户给予特定交易所交易对缓存
      if (data.user_name == 'lightTest1' || data.user_name == 'lightTest2' || data.user_name == 'lightTest3') {
        if(!JSON.parse(localStorage.getItem('tradingPairDefault'))[data.user_name]){
          setTradingPairInfo({
            exchange: 'Pancake',
            web: 'pancake',
            yenUnit1: 'CAKE',
            yenUnit2: 'BNB',
            web_id: '125',
            web_type: '1',
          })
        }
      }
      // 存储登陆时间
      var login_time = new Date().getTime()
      localStorage.setItem('login_time', login_time)

      // 添加切换账号列表记录
      let account = {
        login_token: data.login_token || '',
        login_time,
      }
      commit('ADD_ACCOUNT_ITEM', { accountName: data.user_name, account })
      // 添加账号状态完成
      localStorage.setItem('addAccount',null)

      resolve()
    })
  },

  // get user info
  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getInfo({ account: state.account }).then(response => {
        const data = response
        const { user_name, type, telegram, available_time, code, invite_url, email, safe_login, multi_login, ip_check, pay_set } = data
        commit('SET_NAME', user_name)
        commit('SET_TYPE', type)
        commit('SET_AVAILABLETIME', available_time)
        commit('SET_TELEGRAM', telegram)
        commit('SET_EMAIL', email)
        commit('SET_SAFE_LOGIN', safe_login)
        commit('SET_MULTI_LOGIN', multi_login)
        commit('SET_IP_CHECK', ip_check)
        commit('SET_CODE', code)
        commit('SET_INVITE', invite_url)
        commit('SET_PAY_SET', pay_set)
        // console.log(data)
        resolve(data)
      }).catch(error => {
        reject(error)
      })
    })
  },

  // user logout
  logout({ commit, state }) {
    return new Promise((resolve, reject) => {
      // 删除切换账号列表记录
      commit('DEL_ACCOUNT_ITEM', state.account)

      logout({ account: state.account }).then(res => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        // 初始化盘口选择弹窗判定
        localStorage.setItem('hasBind', 0)
        localStorage.setItem('hasEnter', 0)
        resolve()
      }).catch(error => {
        commit('SET_TOKEN', '')
        commit('SET_NAME', '')
        // 初始化盘口选择弹窗判定
        localStorage.setItem('hasBind', 0)
        localStorage.setItem('hasEnter', 0)
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit, state }) {
    return new Promise(resolve => {
      // 删除切换账号列表记录
      commit('DEL_ACCOUNT_ITEM', state.account)

      commit('SET_TOKEN', '')
      commit('SET_NAME', '')
      // 初始化盘口选择弹窗判定
      localStorage.setItem('hasBind', 0)
      localStorage.setItem('hasEnter', 0)
      resolve()
    })
  },

  // 开/关 新手引导
  setNovice({ commit }, novice) {
    localStorage.setItem('novice', novice)
    commit('SET_NOVICE', novice)
  },

  // 加载切换账号列表
  loadAccountList({ commit }) {
    let accountListStr = localStorage.getItem('account_list')
    if (accountListStr) {
      var accountList = JSON.parse(accountListStr);
    } else {
      var accountList = {};
    }
    commit('SET_ACCOUNT_LIST', accountList);
  },

  // 切换账号更新数据 login_token、user_name、login_time
  switchAccountInit({ commit }, data) {
    // 登录后缓存token及用户名
    commit('SET_TOKEN', data.login_token || '')
    commit('SET_NAME', data.user_name || '')

    // 特殊账户给予特定交易所交易对缓存
    if (data.user_name == 'lightTest1' || data.user_name == 'lightTest2' || data.user_name == 'lightTest3') {
      if(!JSON.parse(localStorage.getItem('tradingPairDefault'))[data.user_name]){
        setTradingPairInfo({
          exchange: 'Pancake',
          web: 'pancake',
          yenUnit1: 'CAKE',
          yenUnit2: 'BNB',
          web_id: '125',
          web_type: '1',
        })
      }
    }
    // 存储登陆时间
    localStorage.setItem('login_time', data.login_time)

    // 刷新页面
    window.location.reload();
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}