import request from '@/utils/request'
import request_down from '@/utils/request_down'
import { aes_encrypt,aes_decrypt } from '@/utils/aes.js'
import QS from 'qs';

export function http(url, params,method){    
    return new Promise((resolve, reject) =>{      
        var data=aes_encrypt(params)

        // 空数据
        if(Object.keys(params).length == 0){
            var obj={data:''}
        }else{
            var obj={data}
        }

        request({
            url,
            method,
            data:QS.stringify(obj),
            timeout: 30 * 1000, // 超过30s提示超时
        }).then(res => {
            if(!!res.decrypt&&res.decrypt=='0'){
                resolve(res.data);
            }else if(res.data==1){
                resolve(res.data);
            }else{
                var data=aes_decrypt(res.data)
                // console.log('data',data)
                resolve(data);
            }
        }).catch(err =>{
            reject(err)        
        })
    });
}
/**
 * 请求返回不加密
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function http_no_aes(url, params,method){    
    return new Promise((resolve, reject) =>{  
        var data=aes_encrypt(params)
        var obj={data}
        request({
            url,
            method,
            data:QS.stringify(obj),
            timeout: 30 * 1000, // 超过30s提示超时
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err)        
        })
    });
}

/**
 * 加长响应时间
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function http_longtime(url, params,method){    
    return new Promise((resolve, reject) =>{      
        var data=aes_encrypt(params)  
        var obj={data}
        request({
            url,
            method,
            data:QS.stringify(obj),
            timeout: 5 * 60 * 1000, // 超过5min提示超时
        }).then(res => {
            if(!!res.decrypt&&res.decrypt=='0'){
                resolve(res.data);
            }else if(res.data==1){
                resolve(res.data);
            }else{
                var data=aes_decrypt(res.data)
                // console.log('data',data)
                resolve(data);
            }
        }).catch(err =>{
            reject(err)
        })
    });
}
/**
 * 加长响应时间 请求返回不加密 
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function http_no_aes_longtime(url, params,method){    
    return new Promise((resolve, reject) =>{      
        var data=aes_encrypt(params)  
        var obj={data}
        request({
            url,
            method,
            data:QS.stringify(obj),
            timeout: 5 * 60 * 1000, // 超过5min提示超时
        }).then(res => {
            resolve(res.data);
        }).catch(err =>{
            reject(err)        
        })
    });
}

/**
 * 下载 Content-Type text/html;charset=UTF-8
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
 export function http_down(url, params,method){    
    return new Promise((resolve, reject) =>{      
        var data=aes_encrypt(params)

        // 空数据
        if(Object.keys(params).length == 0){
            var obj={data:''}
        }else{
            var obj={data}
        }
        // QS.stringify(obj)
        request_down({url,method,data:obj}).then(res => {
            if(!!res.decrypt&&res.decrypt=='0'){
                resolve(res.data);
            }else if(res.data==1){
                resolve(res.data);
            }else{
                var data=aes_decrypt(res.data)
                // console.log('data',data)
                resolve(data);
            }
        }).catch(err =>{
            reject(err)
        })
    });
}